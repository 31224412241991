import React from 'react'
import "./ChatStyles.css"

export default function Links({Tags, Links}) {

  return (
    <div className='Tags'>
    <h3>Tags</h3>
    <ul>
        {Tags.map((_t,i) => {
            return(
                <li  className="teammate" key={i}>{_t.name}</li>
            )
        })}
    </ul>
    <br/>
    <h3>Links</h3>
    <ul>
        {Links.map((_l,i) => {
            let keys = Object.keys(_l)
            console.log(keys)
            return(
                <li className="teammate">
                    <a href={_l.externalUrl} target="_blank" key={i}>{_l.name}</a>
                </li>
            )
        })}
    </ul>
    </div>
  )
}
