import "./ChatStyles.css"
import 'bulma/css/bulma.min.css';
import { useFrontContext } from '../providers/frontContext';
import React, {useEffect, useState} from 'react';

export default function Teammates() {
  const context = useFrontContext();
  const [messageTeammates,setTeammates] = useState([])
  const assignee = context.conversation.assignee ? context.conversation.assignee : null
  useEffect(() => {
      context.listRecipients()
      .then(response => {
        if (response.results.length > 0) {
          setTeammates(response.results)
        } else {
          setTeammates([]);
        }
      });
  }, [context]);
 const sendPrivateMessage = (Teammate) =>{
    context.createDraft({to:[Teammate.name],content:{type:"text",body:""}})
    .then(() => console.log("Created Draft"))
    .catch((err)=> console.error(err))
  }
  return (
    <div className='Team'>
        <ul>
        <li><h3>Recipients</h3></li>
        {messageTeammates.map((mate, i) => {
            return (
                <li key={i} className='teammate'>
                  {mate.name.includes("@")?mate.name.split("@")[0] : mate.name}
                  &nbsp;
                  <a onClick={()=>sendPrivateMessage(mate)}>email</a>
                  &nbsp;
                  {mate.name.includes("mcquilling")?<a  href="slack://open">chat</a> : ""}
                </li>
            ) 
        })}
        <br></br>
        <li><h3>Assignee</h3></li>
        {
          assignee ? (
   
          <li className="assignee">
            {assignee.name.includes("@")?assignee.name.split("@")[0] : assignee.name}
            &nbsp;
            <a onClick={()=>sendPrivateMessage(assignee)}>email</a>
            &nbsp;
           <a target="_blank" href="slack://open">chat</a> 
          </li>) : <li className="assignee">No Assignees</li>
        }
      
    
        </ul>
    </div>
  )
}
