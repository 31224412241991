import 'bulma/css/bulma.min.css';
import React, {useEffect, useState} from 'react';
import { useFrontContext } from '../providers/frontContext';
import Chat from "./Chat"
import Teammates from "./Teammates"
import Attachments from './Attachments';
import Events from './Events';
import Links from "./Links";

function Tutorial() {
  const context = useFrontContext();
  

 // const user = (context.teammate && context.teammate.name) ? context.teammate.name : 'world';
//const recipient = (context.conversation && context.conversation.recipient && context.conversation.recipient.name) ? context.conversation.recipient.name : 'there';
//const messageLinks = context.conversation.links ? context.conversation.links : []
//const messageTags = context.conversation.tags ? context.conversation.tags : []
const [messageComments,setMessageComments] = useState([])
const [messageTags,setMessageTags] = useState([])
const [messageLinks,setMessageLinks] = useState([])
const convoId = context.conversation.id ?  context.conversation.id :''

  // Watches the context and selected the latest message id from the available messages.
  useEffect(() => {
      context.listComments()
      .then(response => {
        if (response.results.length > 0) {
          setMessageComments(response.results)
        } else {
          setMessageComments([]);
        }
      });
        context.conversation.links ? setMessageLinks(context.conversation.links) : setMessageLinks([]);
        context.conversation.tags ? setMessageTags(context.conversation.tags) : setMessageTags([]);
  }, [context]);

  const [_slider,setSlider] = useState("chat")
  const sliderSettings = ["chat","teammates","msg events","attachments","tags"]
  return (
    <div className="App">
      <div className="tabs is-small is-right" style={{width:"90vw"}}>
        <ul>
          {sliderSettings.map((setting,i) => {
            return(
              <li key={i} className={setting === _slider? "is-active" : ""}  onClick={()=>setSlider(setting)}>
                <a>{setting.toUpperCase()}</a> 
              </li>
            )
          })}
        </ul>
      </div>
      {
        {
          'chat': <Chat Comments={messageComments} TeamMate={context.teammate}/>,
          "teammates": <Teammates />,
          "attachments":<Attachments/>,
          "msg events":<Events messageID={convoId}/>,
          "tags":<Links Tags={messageTags} Links={messageLinks}/>
        }[_slider]
      }
    </div>
  );
}

export default Tutorial;