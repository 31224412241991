import React from 'react'
import { useEffect, useState} from 'react';
import Tutorial from './Tutorial';
import "./FrameStyles.css"
import { FaAngleLeft } from 'react-icons/fa';



export default function Frame({linksArray, context, id, cid}) {
  const [frameLink, setLink] = useState(undefined);
  let filteredLink = ''
  function setOption (index) {
    setParameters(index)
  }

  function setEventsArray (events) {
    let eventPath = '['
    events.forEach(function callback(event, index) {
      if (index < events.length -1) {
        eventPath += JSON.stringify(event) + ","
      } else {
        eventPath += JSON.stringify(event) + "]"

      }
    });
    return eventPath
  }

  function setParameters (currentIndex) {
    console.log(`currentIndex clicked, ${currentIndex}`);
    filteredLink = (context) ? linksArray[currentIndex]?.MessageRouting.replace(/@ID/g, context) : linksArray[currentIndex]?.MessageRouting
    filteredLink = (linksArray[currentIndex]?.User) ? filteredLink.replace(/@User/g, linksArray[currentIndex]?.User) : filteredLink
    filteredLink = (id) ? filteredLink.replace(/@UID/g, id) : filteredLink
    filteredLink = (cid) ? filteredLink.replace(/@C_ID/g, cid) : filteredLink
    filteredLink = (linksArray[currentIndex]?.Channel) ? filteredLink.replace(/@Channel/g, linksArray[currentIndex]?.Channel) : filteredLink
    filteredLink = (linksArray[currentIndex]?.channelType) ? filteredLink.replace(/@channelType/g, linksArray[currentIndex]?.channelType) : filteredLink
    filteredLink = (linksArray[currentIndex]?.Configurabletext) ? filteredLink.replace(/@Configurabletext/g, linksArray[currentIndex]?.Configurabletext) : filteredLink
    linksArray[currentIndex]?.Events.forEach(function callback(event, index) {
      let eventNum = index + 1
      let regex = "@Event[" + eventNum + "]"
      let escaped = regex.replace(/[\\[.+*?(){|^$]/g, "\\$&");
      let re = new RegExp(escaped, "gi");
      console.log(re, event);
      filteredLink = filteredLink.replace(re, event)
      console.log(filteredLink);
    });
    filteredLink = (linksArray[currentIndex]?.Events) ? filteredLink.replace(/\[@Events\]/g, setEventsArray(linksArray[currentIndex]?.Events)) : filteredLink
    setLink(filteredLink)
  }

  function resetOption () {
    setLink(undefined)
  }


  useEffect(()=>{
    if (linksArray.length === 1) {
      setParameters(0)
      console.log("finallink", filteredLink);
      setLink(filteredLink)
    } else {
      setLink(undefined)
    }
   },[linksArray])
  

  console.log("links array", frameLink);
  return (
  <div className="frame_container">

      {(linksArray.length > 1 && !frameLink) ? (
         linksArray.map((rule, index) => {
          return <div key={index}>
            <button className="option_button" onClick={() =>  setOption(index)}>{rule.Configurabletext}</button>
          </div>;
        })
      ) : (linksArray.length > 1 && frameLink) ? (
        <div>
        <div className='header'>
          <img className='img_mcq' src='https://searchclient.mcquilling.com/assets/McQ.png'></img>
          <button className='icon-back' onClick={() =>  resetOption()}>
            <FaAngleLeft  />
          </button>
        </div>
          <iframe style={{width:"100vw",height:"100vh"}} src={frameLink} />
        </div>
     ) : ( 
        (linksArray.length === 1) ?(
        <iframe style={{width:"100vw",height:"100vh"}} src={frameLink} />
      ) : (
        
          <Tutorial />
        )
      )}
      
    </div>
  )
}
