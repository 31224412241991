import React,{useEffect,useState} from 'react'
import "./ChatStyles.css"
import { useFrontContext } from '../providers/frontContext';

export default function Attachments() {
    const context = useFrontContext();
    let [attachments,setAttachments] = useState([])
    let [messages,setMessages] = useState([])
    let [download,setDownload] = useState('')
    useEffect(()=>{
        let _a = []
        let _m = []
        context.listMessages()
        .then(response => {
          if (response.results.length > 0) {
            let Messages = response.results
            Messages.forEach(m =>{ 
              _a.push(...m.content.attachments)
              for( let i in m.content.attachments){
                _m.push(m)
              }
            })
            setAttachments(_a)
            setMessages(_m)
          } else {
            setAttachments([]);
            setMessages([])
          }
        });
    },[context])

  return (
    <div className='Team'>
      <ul> 
        <li><h3>Attached Files</h3></li> 
        {attachments.map((_a,i) =>{
        return(
            <li className="teammate" key={i}>
           {messages[i].date.toLocaleDateString('en-US')} &nbsp; {_a.name}
            </li>
        )
    })}
    </ul>
    </div>
  )
}
