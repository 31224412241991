import { useEffect, useState} from 'react';
import './App.css';
import TestApp from './components/TestApp';
import Tutorial from './components/Tutorial';
import { useFrontContext } from './providers/frontContext';
import Frame from './components/Frame';

function App() {

  //new rules declaration
  let [Rules,setRules] = useState({}) 
  const context = useFrontContext();
  useEffect(()=>{
   fetch(`/rulesConfig.json`)
   .then(r => r.json())
   .then(r => setRules(r))
   .catch(err => setRules({err}))
  },[])
 
  console.log("c ",context);
  console.log("r ",Rules)

  function testPath(path, rule) {
    var currentPath = path == "" ? "empty" : path
    var currentRule = rule == "" ? "empty" : rule

    return currentRule.startsWith(currentPath)
  }

  if (!context  || Object.keys(Rules).length === 0)
    return (
      <div className="App">
        <p>Waiting to connect to the Front context.</p>
        <iframe style={{width:"100vw",height:"100vh"}} src="https://pr.mcquilling.com" />
      </div>
    )

  switch(context.type) {
    case 'noConversation':
      return (
        <div className="App">
          {/* <p>No conversation selected. Select a conversation to use this plugin.</p> */}
          <TestApp/>
        </div>
      );
    case 'singleConversation':
      if(context.conversation){ 

        let contextPath = ""
        let tagsArray = []
        let availableLinks = []
        let validEvents = []

        Rules.forEach(rule => {
          rule.Events.forEach(event => {
            if (!validEvents.includes(event)) {
              validEvents.push(event)
            }
          });
        });

        console.log("validevents: ", validEvents);

        if (context.conversation.links[0]?.externalUrl !== undefined) {
          let lastIndex = context.conversation.links[0]?.externalUrl.lastIndexOf("/")
          contextPath = context.conversation.links[0]?.externalUrl.substr(0, lastIndex)
        }

        context.conversation.tags.forEach(tag => {
          if (validEvents.includes(tag.name)) { 
            tagsArray.push(tag.name)
          }
        });

        let mappedContext = {
          Channel: (context.conversation.inboxes[0]?.name !== undefined) ? context.conversation.inboxes[0]?.name : "",
          User: (context.teammate?.name !== undefined)? context.teammate?.name : "",
          UserId: (context.teammate?.username !== undefined)? context.teammate?.username : "",
          McQuillingContext: contextPath,
          contextID: (context.conversation.links[0]?.externalUrl !== undefined) ? context.conversation.links[0]?.externalUrl.split("/").at(-1) : "",
          Events: tagsArray,
        }

        console.log("mapcontext: ",mappedContext);

        Rules.forEach(rule => {
          let targetRule = JSON.parse(JSON.stringify(rule));
          if (JSON.stringify(targetRule.Events.sort()).toLowerCase() === JSON.stringify(mappedContext.Events.sort()).toLowerCase() &&
          rule.Channel.toLowerCase() === mappedContext.Channel.toLowerCase() && testPath(mappedContext.McQuillingContext, rule.McQuillingContext)) {
            availableLinks.push(rule)
          }
        });

        console.log("available links: ", availableLinks);


        return (
         <>
          <Frame linksArray={availableLinks} context={mappedContext.contextID} id={mappedContext.UserId} cid={context.conversation.id}/>
         </>
        )
      }
      return (
        <div className="App">
          <Tutorial />
        </div>
      );
    case 'multiConversations':
      return (
        <div className="App">
          <p>Multiple conversations selected. Select only one conversation to use this plugin.</p>
        </div>
      );
    default:
      console.error(`Unsupported context type: ${context.type}`);
      break;
  };
}

export default App;