import React,{useEffect, useState} from 'react'
import "./ChatStyles.css"

export default function Events({messageID}) {
    let [events,setEvents] = useState([])

    useEffect(() => {
        setEvents([])
        // header.set("Access-Control-Allow-Origin","http://localhost:3000")
        let url = "https://pluginservice.mcquilling.com/api/events?messageId="+messageID+"&key=" + process.env.REACT_APP_KEY
        const options = {method: 'GET',headers:{
           "Accept":'application/json',
           "Access-Control-Allow-Origin":"*"
        }};
        fetch(url,options)
        .then(res => res.json())
        .then(_e=> setEvents(_e))
        .catch(err => console.error(err))
    },[messageID])
    const toDateTime = (secs) => {
        var t = new Date(1970, 0, 1); // Epoch
        t.setSeconds(secs);
        return t.toLocaleDateString('en-US') + " " + t.toLocaleTimeString('en-US');
    }
  if(events.length < 1) return <img style={{height:"50x",width:"50px"}} src='./loader.gif'/>
  return (
  <table className='eventtable'>
          <thead>
            <tr>
              <th>Date</th>
              <th>Event</th>
              <th>By</th>
            </tr>
          </thead>
          <tbody>
        {events.map((_e,i) =>{
        return (
           <tr className="event" key={i}>
              <td> {toDateTime(_e.emitted_at)} </td>
              <td> {_e.type} </td> 
              <td>{_e.source._meta?_e.source._meta.type:""}</td>
            </tr> 
        )
    })}
    </tbody>
  </table>
  )
}



