import React from 'react'
import "./ChatStyles.css"
export default function Chat({Comments, TeamMate}) {
  return (
    <div className='ChatContainer'>
        {Comments.map((comment,i)=>{
            return (
                <div key={i} className={comment.author.id == TeamMate.id ? 'UserComment':'CommentContainer'}>
                    <p className='Comment'>{comment.content.body}</p>
                    <small>{comment.createdAt.toLocaleString()} • {comment.author.name}</small>
                </div>
            )
        })}
    </div>
  )
}
